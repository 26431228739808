<template>
  <div v-if="formData.id" class="">
    <div class="card full-page">
      <div class="card-header" />
      <div class="card-body inner-scroll">
        <b-link to="/bookings" class="back">
          {{ $t('< Back to bookings list') }}
        </b-link>
        <h1 class="my-2">
          {{ $t('Manage booking') }}
        </h1>
        <div class="row">
          <div class="col-md-6">
            <div class="booking-element">
              <span class="label">{{ $t('Activity name') }}: </span>
              <span class="value">{{ formData.activity.name }}</span>
            </div>
            <div class="booking-element">
              <span class="label">{{ $t('Booking date') }}: </span>
              <span class="value">{{ formData.created_at.split('T')[0] }}</span>
            </div>
            <div class="booking-element">
              <span class="label">{{ $t('Price') }}: </span>
              <span class="value">{{ formData.display_price }}</span>
            </div>
            <div class="booking-element">
              <span class="label">{{ $t('Price unit') }}: </span>
              <span class="value">{{ formData.price_unit }}</span>
            </div>
            <div class="booking-element">
              <span class="label">{{ $t('Price info') }}: </span>
              <span class="value">{{ formData.price_info }}</span>
            </div>
            <div class="booking-element">
              <span class="label">{{ $t('Payment status') }}: </span>
              <span class="value">{{ formData.paid }}</span>
            </div>
            <div v-if="formData.subscription" class="booking-element">
              <span class="label">{{ $t('Subscription') }}: </span>
              <span class="value"
                >{{ $t('Subscription end') }}:
                <strong>{{ formData.activity.to_date }}</strong></span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div v-if="formData.contact" class="contact-data">
              <div class="booking-element">
                <span class="label">{{ $t('Full name') }}: </span>
                <span class="value">{{ formData.contact.full_name }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Email') }}: </span>
                <span class="value">{{ formData.contact.email }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Phone') }}: </span>
                <span class="value">{{ formData.contact.phone }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Zip code') }}: </span>
                <span class="value">{{ formData.contact.zip_code }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Kid name') }}: </span>
                <span class="value">{{ formData.kid.name }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Date of birth') }}: </span>
                <span class="value">{{ formData.kid.birthday }}</span>
              </div>
            </div>
            <div v-if="formData.client" class="contact-data">
              <div class="booking-element">
                <span class="label">{{ $t('Full name') }}: </span>
                <span class="value">{{ formData.client.name }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Email') }}: </span>
                <span class="value">{{ formData.client.email }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Phone') }}: </span>
                <span class="value">{{ formData.client.phone }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Zip code') }}: </span>
                <span class="value">{{ formData.client.zip_code }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Kid name') }}: </span>
                <span class="value">{{ formData.kid.name }}</span>
              </div>
              <div class="booking-element">
                <span class="label">{{ $t('Date of birth') }}: </span>
                <span class="value">{{ formData.kid.birthday }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <h3 class="my-3">
              {{ $t('Actions') }}
            </h3>
            <div class="row actions">
              <div class="col-md-3">
                <button class="btn btn-primary block my-2">
                  {{ $t('Send reminder') }}
                </button>
                <p>
                  {{
                    $t('By clicking this button you will send a reminder via email to the parent.')
                  }}
                </p>
              </div>
              <div class="col-md-3">
                <button class="btn btn-danger block my-2">
                  {{ $t('Cancel booking') }}
                </button>
                <p>
                  {{
                    $t(
                      'By clicking this button you will cancel this parents reservation. If there are any payments associated with this booking they will be refunded via Stripe.'
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingService from '@/services/BookingService'
import { BLink } from 'bootstrap-vue'

export default {
  name: 'Edit',
  components: {
    BLink,
  },
  data() {
    return {
      itemId: this.$route.params.itemId,
      BookingService: new BookingService(),
      formData: {
        id: '',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.BookingService.show(this.itemId).then(res => {
        this.formData = res
      })
    },
  },
}
</script>

<style scoped>
.booking-element {
  border-bottom: 1px dashed #ddd;
  padding: 1em 0.5em;
}
.booking-element .label {
  min-width: 200px;
  font-weight: bold;
  display: inline-block;
}
.actions p {
  font-size: 0.9em;
  color: #555;
}
</style>
